import { render, staticRenderFns } from "./Waitlist.vue?vue&type=template&id=74c27090&scoped=true"
import script from "./Waitlist.vue?vue&type=script&lang=js"
export * from "./Waitlist.vue?vue&type=script&lang=js"
import style0 from "./Waitlist.vue?vue&type=style&index=0&id=74c27090&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74c27090",
  null
  
)

export default component.exports