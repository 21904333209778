<template>
  <div class="pt-3">
    <Loader v-if="!groupWaitlist || groupWaitlist.length == 0" />
    <div class="dashboard__container--body pt-3" style="width:100%;">
      <UserTable :groupUsers="groupWaitlist" :group="group" action="applicants" />
    </div>
</div>
</template>

<style scoped>
  .fa-solid {
    opacity: 0.7;
  }
  .fa-solid:hover {
    opacity: 1;
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
// import algoliasearch from 'algoliasearch/lite';
import UserTable from '@/components/UserTable.vue'
import router from '@/router'
import * as moment from 'moment'

export default {
  props: ['group'],
  name: 'groupWaitlist',
  data: () => ({
    message: '',
    showAll: true,
    performingRequest: false,
    performingRequest2: false,
  }),
  created () {
    if (!this.groupWaitlist || this.groupWaitlist.length < 1) {
      this.$store.dispatch("getGroupWaitlist", this.$route.params.id);
    }
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'groupWaitlist']),
  },
  components: {
    Loader,
    UserTable
  },
  methods: {
    onTextPayroll(user) {
      let newText = {
        name: user.firstName,
        phone: user.phone,
        userId: user.id,
        type: 'updatePayroll'
      }
      this.$store.dispatch('updatePayrollText', newText)
      user.updatePayrollReminderSent
      // fb.usersCollection.doc(user.id).update({
      //   updatePayrollReminderSent: fb.firestore.FieldValue.serverTimestamp()
      // })
    },
    acceptUser(item, index) {
      this.$store.dispatch("acceptWaitlistUserToGroup", {
        user: item,
        group: this.group
      })
      let groupWaitlist = this.groupWaitlist
      groupWaitlist.splice(index, 1)
    },
    deleteUser(item, index) {
      this.$store.dispatch("removeWaitlistUserApplicationFromGroup", {
        group: this.group,
        user: item,
      })
      let groupWaitlist = this.groupWaitlist
      groupWaitlist.splice(index, 1)
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY')
      } else {
        return null
      }
    },
    updateGroup() {
      let group = this.group
      this.$store.dispatch('updateGroup', group)
    },
    onRowClick(params) {
      let url = `/users/` + params.row.id
      router.push(url)
    },
  },
  beforeDestroy () {
    this.performingRequest = null
    delete this.performingRequest
    this.performingRequest2 = null
    delete this.performingRequest2
    this.message = null
    delete this.message
    this.showAll = null
    delete this.showAll
    this.columns = null
    delete this.columns
    this.$store.dispatch("clearGroupWaitlist");
  }
}
</script>